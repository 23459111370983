import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="intro" mdxType="Subpage">
      <h3>{`Course Introduction`}</h3>
      <p>{`Welcome to Web Design I! This course is built to help you master the foundational skills you need in order to build effective websites. First, we tackle the concepts and skills related to creating content for the web. This includes creating web pages and structuring the content we put in those pages using HTML. Next we discuss how to add visual appeal to this content and build out web page layouts using CSS. Along the way we'll discuss principles of contemporary web design, how to plan websites, and how to measure their success.`}</p>
      <p>{`Just like Neo in "The Matrix", you'll be surprised how quickly you begin to "see" the code behind the web and use it to build your own masterpiece.`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/fBDifUjNzbQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </Subpage>
    <Subpage slug="tech-overview" mdxType="Subpage">
      <h3>{`Technology Overview`}</h3>
      <p>{`Watch this introduction to course technologies:`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/fUnvVpmo5ek?list=PLuOViGmL7TfXlFl74Hf84NwSQPR_LDR0d" frameborder="0" allowfullscreen></iframe>
    </Subpage>
    <Subpage slug="about-lessons" mdxType="Subpage">
      <h3>{`More About Lessons`}</h3>
      <p>{`This is the first of many "lessons" you’ll find in this course. Each lesson will walk you through all the required readings in a given unit as well as provide additional notes and links to videos. You’ve encountered some of these conventions already, but here’s a formal orientation to the different color coding you’ll see throughout:`}</p>
      <Callout number="0.1" term="Summary Boxes..." color="alternate" mdxType="Callout">
        <p>{`...will call out key take-aways that summarize or condense the discussion that follows. These are included to help you as you study since much of the material can be highly technical.`}</p>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Watch for callouts like this. They clue you in to an activity that you complete outside of the lesson itself, such as completing reading from your textbook.`}</p>
      </Callout>
      <p>{`Words `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`colored like this`}</code>{` and entered in a monospace font indicate code values or filenames.`}</p>
      <p>{`So read each lesson from start to finish including branching out to any assigned readings as they're assigned. Then as you review each lesson you can focus on the numbered "summary boxes" to organize key concepts. Also note that each lesson ends with a "study tools" section that calls out terms you should memorize or questions you should be prepared to answer on exams.`}</p>
    </Subpage>
    <Subpage slug="above-the-fold" mdxType="Subpage">
      <h3>{`Designing Above the Fold`}</h3>
      <p>{`Let’s take a bird’s eye view of the field of web design. One of your required texts for this course is Brian Miller’s `}<em parentName="p">{`Above the Fold`}</em>{`. It provides general principles of visual design for the web, planning processes, and analytical steps related to web design. We’ll read through this book alongside another text that covers the technical side of web design.`}</p>
      <p>{`Have you ever thought about how the layout of a web page is similar to the folded cover of a traditional printed newspaper? Think about the design and planning that goes into deciding what to print on that front cover.`}</p>
      <p>{`Now, have you ever designed or built a web page? Certainly you’ve visited a web page or two in your lifetime. How do you think designing a web page is similar to designing a folded newspaper? While they certainly have their differences, you might be surprised by how they’re similar.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read the Preface from Miller.`}</p>
      </Callout>
      <p>{`After reading, think back on what you read. What is the concept of designing “above the fold”?`}</p>
      <p>{`Consider why you’re taking this course. Is it something you’re really interested in? Or are you taking it merely because you have to? Have you thought about what kind of job you might land after graduating? How likely do you think it will be that you will be expected to understand web design as a legitimate part of your design skills?`}</p>
      <p>{`Regardless, I hope your curiosity is piqued. Consider setting a few goals that you hope to accomplish in this course. Do you want to understand what is involved in web design better? Do you want to gain additional technical skills? Will understanding the technologies that enable us to assemble sites help you design better sites yourself?`}</p>
      <h4>{`A Personal Story`}</h4>
      <p>{`The following is a short story from the professor who originally designed this course.`}</p>
      <blockquote>
        <p parentName="blockquote">{`"Let me share a story about my own journey with web design. While I might not be the professor teaching the course, as course designer, I’ve assembled the content and activities to help you succeed in meeting the course objectives, and I’m passionate about finding and encouraging more students like me.`}</p>
        <p parentName="blockquote">{`"I was a traditional graphic design major for my undergraduate degree. I succeeded and graduated, and got my first job. I didn’t think I was the strongest designer, but I definitely enjoyed the problem-solving side of design. I enjoyed thinking about what the client needed, using the tools of visual design to accomplish those needs, and the technical skills to execute and produce a design thrilled me. For me, art and design have always included both creativity and technical skills to execute the idea.`}</p>
        <p parentName="blockquote">{`"Then I met a web developer. The more I learned about what he did, the more I was curious. As I was challenged to design web layouts in those early days, I felt that much was different about the context of these designs and I was not prepared. I struggled thinking about some of the constraints back then. But I was intrigued by what my friend could accomplish visually by writing lines of code. And so I learned. The more I learned, the more I wanted to learn. I began to feel like a sculptor. I could see the muddy shape of a site forming at my fingertips. But I was clumsy and I often ended up with just a lump of misshaped clay.`}</p>
        <p parentName="blockquote">{`"But I practiced and kept at it, and before long, I felt like I was actually making art. And as I reflected, I realized that this mash-up of technical execution and visual design was exactly what I was built for. The experience was very similar to when I learned how to paint… but I got much better at coding than I ever did at painting!"`}</p>
        <cite>-- Phil Schanely, MFA</cite>
      </blockquote>
      <p>{`As Miller alluded in this introduction, new fields often emerge from existing ones. The skills, techniques, and processes of the preceding fields contribute to the new ones. But at some point, a distinct field does emerge and must break free; that’s where we are today as web design defines itself as a new field, related in history to several others, but distinct in its own way.`}</p>
      <p>{`This exciting new profession has a lot in common with traditional design. No doubt, the principles of effective visual communication and layout design apply. But there are also many, many differences. The least of which is the extent to which the technical aspects of how sites are implemented in living, breathing code should inform some elements of design. There are limitations, but there are also new, boundless possibilities.`}</p>
      <p>{`You will succeed in this course if you open your mind to learning technical skills that can inform your design process. And you should plan right away for your design sense to be shaken to its core as you consider what is possible in this incredible World Wide Web.`}</p>
      <h4>{`What is Web Design?`}</h4>
      <p>{`We’ve looked briefly at an analogy that web design is related to traditional print design and employs the concept of designing “above the fold.” But, given that the context for web design is very different from the context for traditional print design, we need to talk about some of the basics of this context.`}</p>
      <p>{`What do you know about the Internet? Are you familiar with terms such as Internet browser, operating system, color depth, and monitor resolution? Have you considered how these components impact how we design for this medium? How is the audience different for the web than those for traditional print design? Try to answer these questions as you read the next selection from the Miller text.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read "Introduction to Web Design" from Miller.`}</p>
        <small>*Note that if you are waiting for your book to arrive you can make up this reading as soon as it arrives.*</small>
      </Callout>
      <p>{`So what is web design? In your first reflection essay you’ll be challenged to answer this question.`}</p>
      <p>{`We’ll return to the Miller text later in the course.`}</p>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`Each content lesson will include this “Study Tools” section that will provide a succinct list of key terms you should review in order to help prepare for exams.`}</p>
      <p>{`Be sure you understand the following from the required readings in this Introductory unit:`}</p>
      <ul>
        <li parentName="ul">{`Screen reader`}</li>
        <li parentName="ul">{`Screen resolution`}</li>
        <li parentName="ul">{`Internet browser and examples of common browsers`}</li>
        <li parentName="ul">{`WYSIWYG`}</li>
        <li parentName="ul">{`Web server`}</li>
        <li parentName="ul">{`The process by which a computer access a website`}</li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      